import $ from "jquery";
import Cookies from "js-cookie";

$(".switcher a").on("click", event => {
  event.preventDefault();

  const $link = $(event.currentTarget);
  const href = $link.attr("href");
  const locale = $link.data("locale");

  Cookies.set("_int_locale", locale, { expires: 365 });

  window.location.href = href;
});
