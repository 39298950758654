import $ from 'jquery';


var ready = function() {
  $(".project-icon").mousemove(function(e) {

    var parentOffset = $(this).offset();
    var relX = e.pageX - parentOffset.left;
    var iconWidth = $(this).width();

    var shift = (iconWidth - relX) / iconWidth * 10 - 10;

    // shift = shift > 0 ? shift : 0;

    console.log(shift);

    $(this)
      .find(".project-icon-container img")
      .css("margin-left", shift + "px");
  });

  $(".project-icon").mouseleave(function(e) {
    $(this)
      .find(".project-icon-container img")
      .css("margin-left", "0");
  });
};

$(document).ready(ready);
